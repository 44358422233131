<script setup lang="ts">
import appConfig from "~/app.config";
import { routes } from "~/constants";
const { small, sourceOrigin } = defineProps<{
  small?: boolean;
  sourceOrigin: string;
}>();

const etabWebAppUrl = computed(() => {
  return (
    routes.etabWebAppUrl.baseUrl +
    routes.etabWebAppUrl.signup +
    appConfig.utm.source +
    "internal_" +
    sourceOrigin +
    appConfig.utm.campaign +
    appConfig.utm.etab.web_app
  );
});
</script>

<template>
  <div class="push-web-app" :class="{ small: small }">
    <h2 class="push-web-app-title">{{ $t("COMPONENTS.PUSH_WEB_APP.@TITLE") }}</h2>
    <div class="push-web-app-infos-wrapper">
      <div class="push-web-app-infos">
        <span class="push-web-app-infos-text">{{
          $t("COMPONENTS.PUSH_WEB_APP.@INFOS_TEXT")
        }}</span>
      </div>
    </div>
    <p class="push-web-app-description">
      {{ $t("COMPONENTS.PUSH_WEB_APP.@DESCRIPTION") }}
    </p>
    <div class="push-web-app-cta">
      <ButtonLink
        :url="etabWebAppUrl"
        type="recruteur"
        :label="$t('COMPONENTS.PUSH_WEB_APP.@LABEL')"
        picto="extra-logo"
        :aside="true"
        :external="true"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.push-web-app {
  background-color: $etab-color-light;
  border-radius: 3px;
  width: 100%;
  height: 100%;
  padding: $gutter * 4;
  &-title {
    color: $etab-color;
    text-transform: uppercase;
    font-weight: 800;
    text-align: center;
    margin-bottom: $gutter * 4;
  }
  &-description {
    text-align: center;
    color: $dark;
    margin-bottom: var(--showcase-size-100);
  }
  &-cta {
    display: flex;
    justify-content: center;
    .btn-link {
      padding: 20px var(--showcase-size-050);
      &.picto {
        padding: 20px var(--showcase-size-125) 20px var(--showcase-size-050);
      }
    }
  }
  &-infos {
    display: flex;
    justify-content: center;
    width: 70%;
    padding-left: $gutter * 11;
    position: relative;
    margin: 0 auto;
    align-items: center;
    &:before {
      content: "";
      display: block;
      width: 80px;
      height: 48px;
      position: absolute;
      left: 0;
      background-image: url("/img/laptop.png");
    }
    &-text {
      line-height: 24px;
    }
    &-wrapper {
      display: flex;
      align-items: center;
      text-transform: uppercase;
      font-weight: 400;
      color: $etab-color;
      margin-bottom: $gutter * 4;
    }
  }
  &.small {
    padding: var(--showcase-size-150);
    .push-web-app-title {
      font-size: var(--showcase-size-150);
    }
    .push-web-app-infos {
      width: 100%;
      &-wrapper {
        margin-left: var(--showcase-size-075);
      }
    }
  }
}
</style>
